.header {
	background-color: white;
	padding: 2em 0;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
	flex-grow: 0;
	position: sticky;
	top: 0;
	z-index: 30;

	&__content {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		flex-direction: row;

		@include min(420px) {
			flex-direction: column;
		}

		@include min(666px) {
			flex-direction: row;
		}
	}

	h1 {
		margin: 0;
	}
}

.logo {
	img {
		width: 180px;

		@include min(400px) {
			width: 230px;
		}
	}
}

.hamburger {
	display: inline-block;

	@include min(420px) {
		display: none;
	}
}
