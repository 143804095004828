.main-nav {
	width: 100%;
	flex-shrink: 0;

	@include minmax(1px, 419px) {
		.hamburger + & {
			transition: max-height 0.2s ease-out;
			max-height: 0;
			overflow: hidden;
		}

		.hamburger.is-active + & {
			max-height: 20rem;
			overflow: visible;
		}
	}

	@include min(420px) {
		width: auto;
		flex-direction: row;
	}

	&__list {
		display: flex;
		flex-direction: column;
		justify-content: center;
		flex-wrap: wrap;
		padding: 0;
		margin: 0;
		list-style-type: none;

		@include min(420px) {
			flex-direction: row;
		}
	}

	&__item {
		display: inline-block;
		margin: 10px;
		text-align: center;
		font-size: rem(18px);

		@include min(1400px) {
			margin-left: 25px;
			margin-right: 25px;
		}

		&:first-child {
			margin-left: 0;
		}

		&:last-child {
			margin-right: 0;
		}
	}

	&__link {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		&::after {
			content: attr(data-label);
			font-weight: bold;
			position: relative;
			z-index: -1;
			margin-top: -1.5em;
			color: transparent !important;
		}

		@include link(&) {
			text-decoration: none;
			color: $color-base;

			&[aria-current="page"] {
				font-weight: bold;
				color: $color-brand;
			}
		}

		@include link-over(&) {
			color: $color-brand;
			font-weight: bold;
		}
	}
}
