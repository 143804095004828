::selection {
	background: $color-brand;
	color: $color-dark;
}

@include link {
	color: inherit;
	text-decoration: underline;
}

@include link-over {
	//color: $color-brand;
}

@include link-visited("p a") {
	opacity: 0.8;
}

@include headings(1, 6) {
	&:not(:first-child) {
		margin-top: 1.5em;
	}
}

@include headings(1, 4) {
	line-height: 1.15;
	margin: 0 0 0.75em 0;
	text-align: center;
}

@include headings(2, 6) {
	font-weight: 700;
}

@include headings(5, 6) {
	font-weight: 600;
	margin: 0 0 0.5em 0;
}

.main-heading {
	font-size: rem(54px);
	position: relative;
	padding-bottom: 0.75em;
	margin-bottom: 0.75em;

	&::after {
		content: "";
		position: absolute;
		left: 50%;
		top: 100%;
		margin-top: -6px;
		margin-left: -20px;
		width: 40px;
		height: 6px;
		background-color: $color-brand;
	}
}

h1 {
	font-family: $font-headings;
	font-size: rem(54px);
	font-weight: normal;
	color: $color-tertiary;
}

h2 {
	font-size: rem(30px);
	text-transform: uppercase;
}

h3 {
	font-size: 1.875rem;
	font-weight: normal;
}

h4 {
	font-size: 1.625rem;
}

h5 {
	font-size: 1.375rem;
}

h6 {
	font-size: 1.125rem;
}

:focus {
	outline: 0;
}

.keyboard-focus:focus {
	@extend %default-outline;
}

blockquote {
	padding: 4vw;
	margin: 5vw;
	border-left: 1em $color-brand solid;
	font-size: 1.6em;
	font-style: normal;

	/* cite {
		font-style: normal;
	} */

	p {
		margin: 0;
		position: relative;
	}

	@include min(800px) {
		margin: 4vw;
	}

	@include min(1200px) {
		margin: 3em 5em;
		padding-left: 4em;
		padding-right: 4em;
	}
}

code,
pre {
	font-size: 1em;
	font-family: $font-monospace;
}
