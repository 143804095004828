.footer {
	text-align: center;
	margin-top: auto;
	padding-top: 3em;
	padding-bottom: 3em;
	flex-grow: 0;
	background-color: $color-tertiary;
	color: white;

	@include link {
		color: inherit;
		text-decoration: none;
	}

	@include link {
		color: $color-brand;
	}

	.footer__label {
		color: #d3bbb0;
		font-weight: bold;
		margin-bottom: 0;

		+ p {
			margin-top: 0;
		}
	}
}

.home-footer {
	.footer__content  {
		display: flex;
		flex-direction: column;

		@include min(700px) {
			flex-direction: row;
		}
	}

	.footer__col {
		margin-bottom: 30px;

		@include min(700px) {
			margin-bottom: 0;
			flex-basis: (100% / 3);
			position: relative;

			&:not(:last-child)::after {
				content: "";
				position: absolute;
				right: -3px;
				top: 50%;
				margin-top: -20px;
				width: 6px;
				height: 40px;
				background-color: $color-brand;
			}
		}
	}

	.footer__icon {
		display: inline-flex;
		width: 61px;
		height: 53px;
		justify-content: center;
		align-items: center;
	}
}

.sub-footer {
	.footer__content {
		display: flex;
		flex-direction: column;
		text-align: center;

		@include min(1170px) {
			text-align: left;
			flex-direction: row;
		}
	}

	.footer__heading {
		font-family: $font-headings;
		color: white;
		text-transform: none;
		text-align: left;
	}

	.footer__logo {
		margin-bottom: 35px;

		@include min(1170px) {
			margin-right: auto;
		}
	}

	.footer__label {
		margin-top: 0;
	}

	.footer__contact {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;

		@include min(800px) {
			flex-direction: row;
			align-items: flex-start;
		}

		@include min(1170px) {
			justify-content: flex-start;
		}
	}

	.footer__cols {
		display: flex;
		flex-direction: column;

		@include min(1170px) {
			flex-direction: row;
		}
	}

	.footer__col {
		display: flex;
		margin-bottom: 30px;
		flex-direction: column;
		align-items: center;
		text-align: center;

		@include min(1170px) {
			text-align: left;
			flex-direction: row;

			&:not(:first-child) {
				margin-left: 80px;
			}
		}
	}

	.footer__col-content {
		p {
			margin: 0;
		}
	}

	.footer__section {
		@include min(800px) {
			&:not(:first-child) {
				margin-left: 80px;
			}
		}
	}

	.footer__icon {
		margin-bottom: 6px;

		@include min(1170px) {
			margin-right: 14px;
		}
	}
}
